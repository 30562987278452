<template>
  <div class="book_info">
    <div class="book_info_header">
      <topHeader></topHeader>
    </div>
    <router-view />
    <div class="jy-main-bottom">
      <mainbottom :options="options_bottom"></mainbottom>
    </div>
    <div class="bottom">
      <div class="btottom-center">
        <ul>
          <li>
            <img src="../../assets/images/police.png" alt="" /><span
              >公安网备</span
            >
          </li>
          <li></li>
          <li><span>网文许可证</span></li>
          <li></li>
          <li><span>电信增值业务许可证</span></li>
          <li></li>
          <li><span>ICP备案</span></li>
        </ul>
        <div>
          <p>川公网安备 51019002002293号</p>
          |
          <p>川网文 [2020]2152-468号</p>
          |
          <p>增值电信业务经营许可证川 B2-20201265</p>
          |
          <p class="bei" @click="goBeian()">蜀ICP备18031577号-2</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topHeader from "@/components/main/top-header.vue";
import mainbottom from ".././main/main-bottom";

import { home_two } from "@/https/home.js";
export default {
  components: {
    topHeader,
    mainbottom,
  },
  data() {
    return {
      options_bottom: {
        title: [],
        footer_photo: [],
      },
    };
  },
  created() {
    this.load_data_two();
  },
  mounted() {
    // document.documentElement.scrollTop=0
  },
  methods: {
    load_data_two() {
      home_two().then((res) => {
        // 底部信息
        this.options_bottom.title = [
          res.data.title1,
          res.data.title2,
          res.data.title3,
          res.data.title4,
        ];
        this.options_bottom.footer_photo = res.data.footer_photo;
        this.options_bottom.footer_photo = this.options_bottom.footer_photo.splice(
          0,
          4
        );
      });
    },
    goBeian() {
      window.open("https://beian.miit.gov.cn");
    },
  },
  watch: {
    "$route.query.id"() {
      this.resetSetItem("book_id", this.$route.query.id);
      // sessionStorage.setItem('book_id',this.$route.query.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.book_info {
  background-color: #fafcff;
  // background-color: rgba(237, 231, 218, 1);
  .book_info_header {
    z-index: 200;
    width: 100%;
    position: relative;

    // background-color: rgba(248, 243, 233, 1);
    background-color: #ffffff;
    box-shadow: 0 5px 5px -5px rgba(153, 153, 153, 0.2);
    .top_header {
      // background-color: rgba(248, 243, 233, 1);
      background-color: #ffffff;
      height: 56px;
      /deep/.el-input__inner {
        height: 36px !important;
        border-radius: 50px !important;
      }
      /deep/.input_arr {
        height: 36px;
        line-height: 36px;
      }
      /deep/.seach {
        width: 45px;
        background: #eeeeee;
        color: #999999;
        font-size: 20px;
        height: 36px;
        border-radius: 0 50px 50px 0 !important;
      }
    }
  }
}
.jy-main-bottom {
  padding-bottom: 12px;
  width: 100%;
  background: #29333c;
}

li {
  list-style: none;
}

.bottom {
  background: #29333c;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
}

.btottom-center {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    width: 850px;
    display: flex;
    justify-content: space-around;
    li {
      list-style: none;
      height: 19px;
      line-height: 19px;
      font-size: 14px;

      color: #ffffff;
    }
    li:nth-of-type(1) {
      display: flex;
      align-items: center;
    }
  }
  div {
    width: 850px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    color: #999999;
    margin-top: 11px;
    margin-bottom: 14px;
    .bei {
      cursor: pointer;
    }
  }
}
</style>
