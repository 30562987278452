<template>
    <div class="jy-main-bottom-content container">
        <div class="top">
            <div class="jy-main-bottom-contentL">
                <div v-for="(item, index) in options.title" :key="index">
                    <div v-for="el in item" :key="el.id">
                        <p
                            class="jy-main-bottom-contentLT"
                            style="font-size: 16px"
                            v-show="el.is_title"
                            @click="go_net(el.url)"
                            :class="el.url ? 'hit_it' : ''"
                        >
                            {{ el.text }}
                        </p>
                        <p
                            style="font-size: 14px"
                            :class="el.url ? 'hit_it' : ''"
                            @click="go_net(el.url)"
                            v-show="!el.is_title"
                        >
                            {{ el.text }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="jy-main-bottom-contentR" flex column between align>
                <!-- <div class="jy-main-bottom-contentRT">
                <img
                  :src="logo || '../../assets/images/logo.jpg'"
                  alt=""
                  width="228px"
                  height="70px"
                />
              </div> -->
                <div class="jy-main-bottom-contentRB" flex between align>
                    <div
                        v-for="item in get_footer_banner_info"
                        :key="item.id"
                        class="item_box"
                        :class="item.href ? 'hit_it' : ''"
                        @click="to_link(item)"
                    >
                        <img
                            :src="item.img_url || '../../assets/images/logo.jpg'"
                            alt=""
                            width="55px"
                            height="55px"
                        />
                        <p style="font-size: 12px">{{ item.describe }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="p">
            <p>
                Copyright By 四川月见初花文化传媒有限公司 jyacg.com All rights reserved
            </p>
            <p>
                本站全部作品（包括小说和书评）版权为原创作者所有。本网站仅为网友写作提供上传空间储存平台。本站所收录作品、互动话题、书库评论及本站所做之广告均属第三方行为与本站立场无关。
            </p>
            <p>
                网站严厉打击盗版并协助作者维权，欢迎举报盗版行为，共同维护网络版权环境。
            </p>
            <p>
                声明：请所有作者发布作品时严格遵守国家互联网信息管理办法规定。我们拒绝任何色情、暴力、涉政、涉军等违反国家法律及社会风尚的小说，一经发现，立即删除违规作品，严重者将同时封掉作者账号。
            </p>
            <p>举报邮箱：jubao@jyacg.com</p>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            content: "内容",
            title: "标题标题标题标题",
            logo: require("@/assets/images/logo_1.png"),
            bottomArr: [],
        };
    },
    methods: {
        getBottomArr() {
            // 从后台获取数据
        },
        go_net(url) {
            if (url && url !== "") {
                window.open(url, "_blank");
            }
        },
        to_link(item) {
            if(item.content){
                window.open(item.content);
            }
        },
    },
    props: {
        options: {
            type: Object,
        },
    },
    mounted() {
    },

    computed: {
        ...mapGetters(['get_footer_banner_info'])
    },
};
</script>

<style lang='scss' scoped>
.jy-main-bottom-content {
    height: 100%;
    color: #ffffff;
    position: relative;
    // display: flex;
    // justify-content: space-between;
    // text-align: center;
}

.jy-main-bottom-content .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.jy-main-bottom-content .p {
    width: 100%;
    text-align: center;
    color: #999999;
    font-size: 12px;
    margin-top: 12px;
}

.jy-main-bottom-contentL {
    width: 680px;
    position: relative;
    padding-top: 29px;
}

.jy-main-bottom-contentL > div {
    width: 25%;
    float: left;
    text-align: center;
    // height: 100%;
}

.jy-main-bottom-contentL > div > div {
    margin-bottom: 5px;
}

.jy-main-bottom-contentLT {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;

    a {
        color: #FFF !important;
    }
}

.jy-main-bottom-contentR {
    width: 330px;
    height: 80px;
    text-align: center;
    margin-top: 25px;
}

.jy-main-bottom-contentRT {
    width: 100%;
    height: 70px;
}

.jy-main-bottom-contentRB {
    width: 100%;
    height: 150px;
}

.item_box {
    height: 100px;
    flex-direction: column;
    justify-content: flex-start;
}

.hit_it {
    cursor: pointer;

    &:hover {
        color: #409eff;
    }
}
</style>
